body {}

.page {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 3em;
}

.contact {
    width: 60%;
}

.data {
    display: flex;
    flex-direction: column;
    background-color: var(--ThirtyPercentOrange);
    width: 100%;
    height: 100%;
    border-radius: 20px
}

.info-comment {
    display: flex;
    flex-direction: row;
}

.info {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding: 1em;
}

.client {
    width: 100%;
    border-radius: 10px;
    border: 0px solid #ccc;
    font-size: 25px;
    height: 2em;
}

.space {
    margin-bottom: 0.5em;
}

.comment {
    width: 50%;
    padding: 1em;
}

.comments-text {
    height: 100%;
    width: 100%;
    border-radius: 20px;
    border: 0px solid #ccc;
    font-size: 25px;
    overflow: auto;
    word-wrap: break-word;
}

.submit-div {
    text-align: center;
    padding: 1em;
}

.submit {
    height: 2em;
    width: 10em;
    background-color: #F58730;
    font-size: 25px;
    border-radius: 20px;
    border: 0px solid #ccc;
}

.submit:hover {
    background-color: var(--NiceBlue);
    border-color: var(--NiceBlue);
}

.more-info {
    width: 40%;
    padding: 1em;
    font-size: 16px;
    padding-top: 0;
}

.map-iframe {
    height: 1000px;
}

.image {
    height: 60%;
    width: 99.99%;
}

.top {
    padding-top: 1em;
    padding-left: 1em;
}

.top-title {
    font-size: 40px;
}

.top-sub {
    font-size: 25px;
}

.report {
    text-align: center;
}

p {
    margin: 0;
}

.popup {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: opacity 0.3s ease-in-out;
}

.fake-link {
    color: var(--NiceBlue);
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width:1000px) {
    .page {
        flex-direction: column;
    }

    .info-comment {
        flex-direction: column;
    }

    .info {
        width: 100%;
    }

    .comment {
        width: 100%;
        height: 300px;
    }

    .contact {
        width: 100%;
    }

    .more-info {
        width: 100%;
        padding-top: 1em;
    }

    .image {
        height: 320px;
    }
}