body {}

.board-of-directors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.board-of-directors>div {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
}

.board-of-directors>div>img {
    width: 100%;
    max-width: 200px;
}

.capability-table {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.capability-table>div {
    width: 50%;
    padding: 1em;
}

.timeline {
    width: 60%;
}

@media (max-width: 1000px) {
    .board-of-directors {
        flex-wrap: wrap;
    }

    .board-of-directors>div {
        width: 50%;
        padding: 1em;
    }

    .board-of-directors>div:nth-child(1) {
        width: 100%;
        display: flex;
        justify-content: center;
        /* Center the first item */
    }

    .board-of-directors>div:nth-child(1)>img {
        width: 50%;
        /* Maintain image size consistency */
        max-width: 200px;
    }

    .board-of-directors>div:nth-child(2),
    .board-of-directors>div:nth-child(3),
    .board-of-directors>div:nth-child(4),
    .board-of-directors>div:nth-child(5) {
        width: 50%;
        /* Other items take 50% width */
    }

    .board-of-directors>div>img {
        width: 100%;
        max-width: 200px;
        /* Ensure all images are consistent */
    }

    .timeline {
        width: 100%;
    }


    .capability-table {
        display: flex;
        flex-direction: column;
    }

    .capability-table>div {
        width: 100%;
        padding: 1em;
    }
}