.container{
    margin: 0;
    padding: 0;
}

@media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: none;
    }
}

@media (min-width: 1200px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: none;
    }
}

@media (min-width: 992px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: none;
    }
}

@media (min-width: 768px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: none;
    }
}

@media (min-width: 576px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: none;
    }
}