a {
    color: var(--NiceBlue);
}

code {
    color: #E01A76;
}

body, html {
    padding: 0;
    margin: 0;
}

.page-padding {
    padding: 3em;
    min-height: 75vh;
}

:root {
    --ZeusBrown: #292220;
    --RoseRed: #df1731;
    --CadmiumOrange: #f58730;
    --NiceBlue: #1a75af;
    --TransparentBlue: #1a75af8a;
    --ThirtyPercentOrange: #f587304d;
    --FiftyPercentOrange: #f5873080;
}

.btn-primary {
    color: white;
    background-color: var(--RoseRed);
    border: none;
    border-radius: 20px;
    font-family: Montserrat, sans-serif;
    font-weight: 400; 
}

.roboto-regular {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.montserrat-regular {
    font-family: Montserrat, sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.centered-text {
    text-align: center;
}

.dark {
    color: var(--ZeusBrown)
}

.light {
    color: white;
}

.accordion {
    background-color: var(--ThirtyPercentOrange);
    color: var(--ZeusBrown);
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    border-radius: 25px;
    outline: none;
}

.accordion:hover {
    background-color: var(--FiftyPercentOrange);
}

.active {
    background-color: var(--FiftyPercentOrange);
    border-radius: 25px 25px 0px 0px;
}

.panel {
    padding: 0 18px;
    background-color: var(--FiftyPercentOrange);
    color: var(--ZeusBrown);
    overflow: hidden;
    margin-bottom: 1em;
    border-radius: 0px 0px 25px 25px;
    text-align:left;
    font-size: larger;
}

.right-arrow-image {
    display: block;
    margin-left: auto;
    width:3%;
    height: 3%;
    margin-right:1em;
}

.accord-text {
    font-size: 28px;
}

.unstyled-links-black {
    color: #292220;
    text-decoration: none;
}

unstyled-links-black:visited {
    color: #292220;
    text-decoration: none;
}

unstyled-links-black:hover {
    color: #292220;
    text-decoration: none;
}

unstyled-links-black:active {
    color: #292220;
    text-decoration: none;
}

.unstyled-links-white {
    color: white;
    text-decoration: none;
}

.unstyled-links-white:visited {
    color: white;
    text-decoration: none;
}

.unstyled-links-white:hover {
    color: white;
    text-decoration: none;
}

.unstyled-links-white:active {
    color: white;
    text-decoration: none;
}

@media (max-width:1000px) {
    .right-arrow-image {
        width: 5%;
        height: 5%;
    }

    .accord-text {
        font-size: 20px;
    }
}